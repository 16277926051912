<template>
    <section class="sabc_tier_sports_area">
        <div class="container">
            <div class="row">
                <div class="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div class="sabc_tier_sports_title_tabs">
                        <!-- <h1 v-if="$route.params.sport == 'football'">
                            Soccer
                        </h1>
                        <h1 v-else>
                            {{(!$route.params.sport)? ($route.path.split('/')[2].charAt(0).toUpperCase()+$route.path.split('/')[2].slice(1)).split('-').join(' ') : ($route.params.sport.toLowerCase().split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '))}} {{($route.params.sport == 'other')? 'Sports' : ''}}
                        </h1>
                        <ul>
                            <li :class="{active: ($route.path.match('/'+($route.params.sport)+'/news')) }">
                                <router-link :to="'/'+ ((!$route.params.sport)? ($route.path.split('/')[2]) : $route.params.sport) +'/news'">{{ $t("News") }}</router-link>
                            </li>
                            <li :class="[{active: ($route.path.match(/live/)) }, {hide: (($route.params.sport == 'cricket' || $route.params.sport == 'football' || $route.params.sport == 'rugby') || ($route.path.match('live/cricket') || $route.path.match('live/football') || $route.path.match('live/rugby') ))? false: true}]">
                                <router-link :to="'/live/'+( (!$route.params.sport)? ( $route.path.split('/')[2] ): $route.params.sport )+'/matches/all/'+formatDate(new Date())">Scores</router-link>
                            </li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

    export default {
        name: "SportOptions",
        data() {
            return {}
        },
        mounted() {},
        methods: {
        }
    }

</script>

<style scoped>
    .hide {
        visibility: hidden !important;
    }
</style>
