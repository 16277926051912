<template>
    <div class="text-center" v-if="!is_data_ready">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div v-else-if="is_data_ready">
        <!--  Content Start -->
        <!-- featured area start -->
        <SportOptions></SportOptions>
        <!-- featured area end -->
        <!-- featured area start -->
        <TopStories v-if="topStories.length" :topStories="topStories"></TopStories>
        <!-- featured area end -->
        <section class="sabc_tier_sports_sec">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-xl-8 col-lg-8 col-md-12 col-sm-12">
                        <TopTireNews v-if="topTireNews" :tournamentNames = "topTireNews"></TopTireNews>
                         <!-- <TournamentNews v-if="tournamentNews" :tournamentNews = "tournamentNews"></TournamentNews> -->
                        <!-- <div v-if="latestNews.length">
                            <div class="sabc_tier_news_tabs">
                                <h2 v-if="$route.params.sport == 'all-sports'">Latest News</h2>
                                <h2 v-else-if="$route.params.sport == 'football'">Latest Soccer News</h2>
                                <h2 v-else>Latest {{$route.params.sport.toLowerCase().split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}} News</h2>
                            </div>
                            <div class="sabc_tier_news_sec">
                                <div class="sabc_tier_news_row" v-for="article in latestNews">
                                    <div class="sabc_tier_news_pic">
                                        <router-link :to="article.articleSection.section.slug+article.slug">
                                            <img :src="$resizeImage(article.image.image_path, 368, 232)" :alt="article.image_caption" loading="lazy"/>
                                        </router-link>
                                    </div>
                                    <div class="sabc_tier_news_desc">
                                        <h2>
                                            <router-link :to="article.articleSection.section.slug+article.slug">
                                                {{article.headline}}
                                            </router-link>
                                        </h2>
                                        <p v-html="article.stand_first_simple">
                                        </p>
                                        <label>
                                            {{article.start_date | formatDate2}}
                                        </label>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center mb-20">
                                    <button @click="getSportsData(offset+1)" class="btn btn-primary mb-5 mt-2" :class="[{'invisible' : (paginate['current_page'] == Math.ceil(paginate['total']/8)) }]">Load More</button>
                                </div>
                            </div>
                        </div> -->
                    </div> 
                    <SideBar :editorsPick="editorsPick" :olympicsTable="olympicsTable" :bannerImages="bannerImages" :bannerEmbed="bannerEmbed"></SideBar>
                </div>
            </div>
        </section>
        <!--  Content end -->
    </div>
</template>

<script>
    import LatestNews from '@/components/common/latest-news'
    import TopStories from '@/components/common/top-stories'
    import SportOptions from '@/components/common/sport-options'
    import KeySeries from '@/components/key-series/key-series'
    import SideBar from '@/components/common/sidebar/sidebar';
    import TournamentNews from '@/components/Sports/tournament-news';
    import TopTireNews from '@/components/Sports/toptire-news';

    export default {
        name: "home",
        jsonld() {
            if (isServer)
                return {
                    "@context": "http://schema.org",
                    "@graph": []
                }

            if (!this.latestNews)
                return

            let events = [];
            this.latestNews.forEach((article) => {
                let path = this.$siteURL.replace(/\/+$/, '') + article.articleSection.section.slug+article.slug;

                events.push(
                    {
                        '@type': 'NewsArticle',
                        mainEntityOfPage: {
                            '@type': 'WebPage',
                            '@id': path
                        },
                        headline:  article.headline,
                        description: article.meta_description,
                        image:  article.image.image_path,
                        author: {
                            '@type': 'Person',
                            name: article.author.display_name,
                            "url" : this.$siteURL
                        },
                        publisher: {
                            '@type': 'Organization',
                            name: "SABC",
                            logo: {
                                '@type': 'ImageObject',
                                url: this.$siteURL + 'public/images/logo.png'
                            }
                        },
                        datePublished: article.created_at,
                        dateModified: article.updated_at
                    }
                )
            });
            return {
                "@context": "http://schema.org",
                "@graph": events
            };
        },
        data() {
            return {
                url: '',
                tournamentNews: [],
                topTireNews: [],
                latestNews: [],
                topStories: [],
                editorsPick: [],
                section: [],
                olympicsTable: '',
                sport_name: '',
                offset : 1,
                bannerImages : [],
                bannerEmbed : [],
                paginate : [],
                is_data_ready: true
            }
        },
        components: {
            LatestNews, TopStories, SportOptions, KeySeries, SideBar, TournamentNews, TopTireNews
        },
        mounted() {
            this.getSportsData(this.offset);
        },
        serverPrefetch() {
            return this.getSportsData(this.offset);
        },
        watch: {
            '$route': function (to, from) {
                this.latestNews = [];
                this.offset = 1;
                this.tournamentNews = null;
                this.getSportsData(this.offset);
            }
        },
        metaInfo() {

           if (this.$store.state.siteSetting) {
                let ss = this.$store.state.siteSetting;
                let sportKey = (this.$route.params.sport).replace(/-/g, '_');

                var replacements = {
                    "%SECTION_TILTE%": this.section.meta_title,
                    "%SECTION_DESCRIPTION%": this.section.meta_description,
                    "%SECTION_KEYWORD%": this.section.meta_keywords,
                    "%SPORT%": this.$route.params.sport
                };

                let title = this.$getSafe(() => this.$replacePlaceHolders(ss[sportKey+'_title'],replacements));
                let description = this.$getSafe(() => this.$replacePlaceHolders(ss[sportKey+'_meta_description'],replacements));
                let keywords = this.$getSafe(() => this.$replacePlaceHolders(ss[sportKey+'_meta_keywords'],replacements));

                return {
                    title: (title) ? title : 'Latest Sport News, Reports and Latest Trends - SABC Sport',
                    description: description,
                    meta: this.$getMetaTags(title, description, keywords),
                    link: [
                        {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}
                    ],
                }
            }
        },
        methods: {
            getSportsData(offset) {
                if(this.$route.params.sport == 'football'){
                   return this.$router.push({ name: 'sport_home', params: {sport:'soccer', news: 'news' } })
                }
                this.offset = offset;
                this.sport_name = this.$route.params.sport;
                this.url = this.$GetSportsData + '?sport=' + this.sport_name + '&page=' + offset;
                return this.$axios.get(this.url)
                        .then(response => {
                            if (response.data) {
                                // this.latestNews = this.latestNews.concat(response.data.latestNewsAll.data);
                                // this.paginate['total'] = response.data.latestNewsAll.total;
                                // this.paginate['current_page'] = response.data.latestNewsAll.current_page;
                                this.topStories = response.data.topStories;
                                this.editorsPick = response.data.editorsPick;
                                this.olympicsTable = response.data.olympicsTable;
                                // this.tournamentNews = response.data.tournamentNews;
                                this.topTireNews = response.data.topTireNews;
                                this.section = response.data.section;
                                this.bannerImages = response.data.bannerImages;
                                this.bannerEmbed = response.data.bannerEmbed;
                                // if (this.tournamentNews.length == 0) {
                                //     this.tournamentNews = null;
                                // }
                                this.is_data_ready = true;
                            }
                        })
                        .catch(error => {
                        })
            },

        }
    }
</script>
<style lang="scss">
@import '~@/assets/scss/featured.scss';
@import '~@/assets/scss/tier_sports.scss';
@import '~@/assets/scss/right_sidebar.scss';
</style>
