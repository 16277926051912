<template>
    <div class="widget-box">
      <header class="widget-heading">
        <h1> {{ $t("Latest Headlines") }}</h1>
      </header>
      <div class="row competitions-post">
        <div class="col-md-6 col-xl-7 pr-xl-30 main-post-column">
          <article v-for="article in latestNews.slice(0,2)" class="main-post">
            <div class="post-img">
              <router-link :to="{ name: 'sport_news_detail', params: { sport:  $getMatchedSportsName(article.sport_id),title:article.slug}}">
                <img :src="$resizeImage(article.image.image_path,400,400)" width="400" height="400" :alt="article.image_caption" loading="lazy">
              </router-link>
            </div>
            <div class="post-descrip">
              <h2><router-link :to="{ name: 'sport_news_detail', params: { sport: $getMatchedSportsName(article.sport_id),title:article.slug}}">{{article.headline}}</router-link></h2>
              <time class="date" :datetime="article.created_at  | formatDate2('DD MM YYYY')">{{ article.created_at  | formatDate2('DD MM YYYY')}}</time>
              <p></p><p>{{article.meta_description}}</p>
            </div>
          </article>
        </div>
        <div class="col-md-6 col-xl-5 news-list">
          <div class="news-item" v-for="article in latestNews.slice(2)">
            <div class="item-img">
              <router-link :to="{ name: 'sport_news_detail', params: { sport: $getMatchedSportsName(article.sport_id),title:article.slug}}">
                <img :src="$resizeImage(article.image.image_path,100,100)" width="100" height="100" :alt="article.image_caption" loading="lazy"></router-link>
            </div>
            <div class="item-descrip">
              <h3><router-link :to="{ name: 'sport_news_detail', params: { sport: $getMatchedSportsName(article.sport_id),title:article.slug}}">{{article.headline}}</router-link></h3>
              <time class="date" :datetime="article.created_at  | formatDate2('DD MM YYYY')">{{article.created_at  | formatDate2('DD MM YYYY')}}</time>
            </div>
          </div>

        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'LatestNews',
  props: ['latestNews'],
  data() {
    return {
    }
  },

  mounted() {
  },

  methods: {

  }
}
</script>
