<template>
  <div class="widget-box" v-if="keySeries">
    <header class="widget-heading">
      <h2>{{ $t("Key Series") }}</h2>

    </header>
    <div class="swiper-container key-series key-post-slider0 swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events swiper-container-free-mode swiper-container-multirow">
      <div class="swiper-head">
        <ul class="filter-post">
          <li v-for="TabKey,t_index in keySeries" :key="t_index" @click="doActive(TabKey[0].tournament.name)" :class="{active: (TabKey[0].tournament.name == aTab)? true: false }">
            <a href="javascript:;">{{TabKey[0].tournament.name}}</a><!-- href="javascript:changeKeySeriesTab(0)" -->
          </li>
        </ul>
        <div class="swiper-button-next" tabindex="0" role="button" aria-label="Next slide" aria-controls="swiper-wrapper-911aaef3ca8fc665" aria-disabled="false"></div>
        <div class="swiper-button-prev swiper-button-disabled" tabindex="-1" role="button" aria-label="Previous slide" aria-controls="swiper-wrapper-911aaef3ca8fc665" aria-disabled="true"></div>
      </div>

       <div class="swiper-wrapper"  id="swiper-wrapper-911aaef3ca8fc665" aria-live="polite" style="width: 19995px; transform: translate3d(0px, 0px, 0px);">
          <div v-for="each_series in selectedTabKeySeries" :key="each_series.id">
            <div  class="swiper-slide key-post swiper-slide-active" role="group" style="width: 249.667px; margin-right: 10px;">
          <div class="img-holder">
                <router-link :to="{ name: 'sport_news_detail', params: { sport: $getMatchedSportsName(each_series.sport_id),title:each_series.slug}}">
                  <img :src="$resizeImage(each_series.image.image_path,250,170)" width="245" height="150" :alt="each_series.image_caption">
                </router-link>
          </div>
              <h3><router-link :to="{ name: 'sport_news_detail', params: { sport: $getMatchedSportsName(each_series.sport_id),title:each_series.slug}}">{{each_series.headline}}</router-link></h3>
          <ul class="meta2">
                <li><router-link :to="''" class="badge badge-green">{{each_series.tournament.name}}</router-link></li>
                <li><time class="date" :datetime="each_series.start_date  | formatDate2('DD MM YYYY')">{{ each_series.start_date  | formatDate2('DD MM YYYY')}}</time></li>
          </ul>
        </div>

      </div>
      </div>

      <span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span></div>
  </div>

</template>

<script>
export default {
  name: "key-series",
  props: ['keySeries'],
  data() {
    return {
      aTab: '',
      selectedTabKeySeries: [],
      keySeriesData : {},
    }
  },
  watch:{
      keySeries:function(data) {
          if(data) {
              let keys = Object.keys(data);
              this.aTab = keys[0];
              this.keySeriesData = data;
              this.getTabKeySeries(this.aTab);
          }
      }
  },
  methods: {
    doActive(key) {
      this.aTab = key;
      this.getTabKeySeries(this.aTab);
    },
    getTabKeySeries(selectedTab) {
        this.selectedTabKeySeries = this.keySeriesData[selectedTab];
  }
}
}
</script>
